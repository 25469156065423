/************
 * Author : Sakthipriya
 * Developed by : Shiva Software Solutions
 * Date : 11-05-2023
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  GET_CLIENT_MASTERS,
  GET_OPERATOR_LIST,
  GET_CAMERA_LIST,
  GET_CAMERA_LIST_EXIST,
  GET_BUZZER_LIST,
  GET_MAPPED_AGENTS,
  GET_MAPPED_AGENTS_COUNT,
  UPDATE_AGENT_MSG,
  GET_DOCTOR_ADDEDLIST,
  GET_AVAILABLE_CAMERALIST,
  UPDATE_SUCCESS_ROOM,
  GET_ROOMS_LIST,
  GET_ROOMS_LIST_COUNT,
  GET_CLIENT_CATEGORY,
  CLIENT_USER_CAT
} from '../../shared/constants/ActionTypes';
const initialState = {
  clientlist: [],
  operatorslist: [],
  cameraBuzzer: [],
  cameraDash: [],
  updateAgentMsg: '',
  assignedDocList: [],
  mappedAgentsList: [],
  mappedAgentsListCount: 0,
  availableCameras: [],
  floorRoomList: [],
  floorRoomListCount: 0,
  updateSuccessRooms: {},
  clientCategoryList: [],
  clientUserCat:[]
};

const cameraDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_MASTERS:
      return {
        ...state,
        clientlist: action.payload,
      };
      case CLIENT_USER_CAT:
        return {
          ...state,
          clientUserCat: action.payload,
        };
    case GET_OPERATOR_LIST:
      return {
        ...state,
        operatorslist: action.payload,
      };
    case GET_CAMERA_LIST:
      return {
        ...state,
        cameraDash: action.payload,
      };
    case GET_CAMERA_LIST_EXIST:
      return {
        ...state,
        cameraDashExist: action.payload,
      };
    case GET_BUZZER_LIST:
      return {
        ...state,
        cameraBuzzer: action.payload,
      };
    case GET_MAPPED_AGENTS:
      return {
        ...state,
        mappedAgentsList: action.payload,
      };
    case GET_MAPPED_AGENTS_COUNT:
      return {
        ...state,
        mappedAgentsListCount: action.payload,
      };
    case UPDATE_AGENT_MSG:
      return {
        ...state,
        updateAgentMsg: action.payload,
      };
    case GET_DOCTOR_ADDEDLIST:
      return {
        ...state,
        assignedDocList: action.payload,
      };
    case GET_AVAILABLE_CAMERALIST:
      return {
        ...state,
        availableCameras: action.payload,
      };
    case GET_ROOMS_LIST:
      return {
        ...state,
        floorRoomList: action.payload,
      };
    case GET_ROOMS_LIST_COUNT:
      return {
        ...state,
        floorRoomListCount: action.payload,
      };
    case GET_CLIENT_CATEGORY:
      return {
        ...state,
        clientCategoryList: action.payload,
      };
    case UPDATE_SUCCESS_ROOM:
      return {
        ...state,
        updateSuccessRooms: action.payload,
      };
    default:
      return state;
  }
};
export default cameraDashboardReducer;
