/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 * Dependicies : To use @material-ui/core
 ************/

import {authRole} from '../shared/constants/AppConst';

const traumaRouteConfig = [

  {
    id: 'traumaDashboard',
    title: 'Trauma Dashbaord',
    messageId: 'sidebar.traumaDashboard',
    type: 'single',
    icon: 'local_hospital',
    auth: authRole.traumaRoles,
    url: '/traumaDashboard',
  },
  
];
export default traumaRouteConfig;
