/************
 * Developed by : Shiva Software Solutions
 * Date : 01-01-2024
 * Descriptions : Digitization api calling
 ************/

import React from 'react';
import Api from '../../@crema/services/ApiConfig';
import {
  OTP_TIME,
  DIGI_LIST_RESULT,
  FETCH_ERROR,
  FETCH_SUCCESS,
  DIGI_L1TAG,
  DIGI_DOSAGE_OPTIONS,
  FETCH_START,
  DIGI_SEARCHMEDICINE_DATA,
  DIGI_GENERAL_SETTINGS,
  DIGI_SETTINGS_UPDATE,
  DIGI_NEWMEDICINE_DATA,
  DIGI_MEDINSERT_SUCCESS,
  SEGREGATION_SUCCESS,
  REVERTSEGREGATION_SUCCESS,
  DIGI_TOASTER,
  DIGI_SUCCESS_MSG,
  DIGI_PRESCRIPTIONNUMBER,
  PRESCRIPTION_OCR_DATA,
  DIGI_MED_SUGG,
  DIGI_UOMLIST,
  DIGI_MEDCATEGORY,
  SEND_AMAZON_SUCCESS_MSG,
  DIGI_FINAL_SUCCESS,
  VERIFICATION_SUCCESS,
  PRESCRIPTIONINFO_SUCCESS,
  ISSUE_DATE,
  GET_DOCTOR_EDITDATA,
  GET_DOCTOR_SUGGESTION_HOSPITAL,
  DOCTORS_SUGGESTIONS,
  DOCTOR_AUTOFILL,
  DIGITIZATION_DOCTORS_SUGGESTIONS,
  DIGITIZATION_DOCTOR_AUTOFILL,
  GET_NER_HOSPITALS,
  DIGI_REJECT_COUNT,
  DIGI_FAILURE_DATA,
  DIGI_FAILURE_COUNT,
  DIGI_COMPLETED_COUNT,
  DIGI_REJECT_DATA,
  DIGI_INPROGRESSS_COUNT,
  DIGI_COMPLETED_DATA,
  DIGI_INPROGRESSS_DATA,
  TOTAL_DIGI_COMPLETED_COUNT,
  TOTAL_DIGI_PENDING_COUNT,
  TOTAL_DIGI_FAILURE_COUNT,
  TOTAL_DIGI_INPROGRESS_COUNT,
  TOTAL_DIGI_REJECT_COUNT,
  TOTAL_AMAZON_PENDING_COUNT,
  DIGI_PENDING_DATA,
  DIGI_PENDING_COUNT,
  VITALS_SETTINGS_UPDATE,
  DIGI_PRESCRIPTION_IMAGES,
  DIGI_PRESCRIPTION_DATA,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
import IntlMessages from '../../@crema/utility/IntlMessages';
var streamname = 'Redux_Actions';
var filename = 'Digitization.js';
export const onGetDigitizationMed = (value) => {
  return (dispatch) => {
    try {
      jwtAxios
        .post('v1/digitization/searchAmazonMedJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/searchAmazonMed', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({
                    type: DIGI_SEARCHMEDICINE_DATA,
                    payload: data?.data?.body ? data.data.body : [],
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDigitizationMed',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDigitizationMed',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigitizationMed',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigitizationMed',
          '0',
          err,
        ),
      );
    }
  };
};

export const onSegregationProcess = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/SegregatePrescriptionsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/SegregatePrescriptions', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: PRESCRIPTION_OCR_DATA,
                    payload: data?.data?.body?.pres_Data,
                  });
                  dispatch({
                    type: ISSUE_DATE,
                    payload: data?.data?.body?.pres_Data[0]?.issueDate
                      ? data.data.body.pres_Data[0].issueDate
                      : '',
                  });
                  dispatch({
                    type: DIGI_TOASTER,
                    payload: 'Segregation Saved Successfully',
                  });
                  dispatch({
                    type: SEGREGATION_SUCCESS,
                    payload: 'success',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onSegregationProcess',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSegregationProcess',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSegregationProcess',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSegregationProcess',
          '0',
          err,
        ),
      );
    }
  };
};

export const onRevertProcess = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/revertDigitizationProcessJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/revertDigitizationProcess', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: REVERTSEGREGATION_SUCCESS,
                    payload: 'success',
                  });
                  dispatch({
                    type: DIGI_TOASTER,
                    payload: 'Digitization reverted',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onSegregationProcess',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSegregationProcess',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSegregationProcess',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSegregationProcess',
          '0',
          err,
        ),
      );
    }
  };
};

export const updateImageValidation = (value, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/updateImageValidationJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/updateImageValidation', value)
              .then((data) => {
                console.log(data, 'dataimg');
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  let message = data?.data?.body;
                  console.log(message, 'message');
                  dispatch({
                    type: DIGI_TOASTER,
                    payload: message,
                  });
                  // dispatch({
                  //   type: SEND_AMAZON_SUCCESS_MSG,
                  //   payload: message,
                  // });
                  dispatch({
                    type: VERIFICATION_SUCCESS,
                    payload: 'Success',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateImageValidation',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateImageValidation',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateImageValidation',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateImageValidation',
          '0',
          err,
        ),
      );
    }
  };
};

export const updateImageValidationReject = (value, history, dashboardTag) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/updateImageValidationJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/updateImageValidation', value)
              .then((data) => {
                console.log(data, 'dataimg');
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  let message = data?.data?.body;
                  console.log(message, 'message');
                  if (value?.status && Number(value?.status?.id) == 2) {
                    dispatch({
                      type: DIGI_TOASTER,
                      payload: message,
                    });
                  }
                  // dispatch({
                  //   type: SEND_AMAZON_SUCCESS_MSG,
                  //   payload: message,
                  // });
                  dispatch({
                    type: VERIFICATION_SUCCESS,
                    payload: 'Success',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateImageValidation',
                    '0',
                    error,
                  ),
                );
              })
              .finally((data) => {
                if (dashboardTag && (data === undefined || data === null)) {
                  history.push('/digitizationdashboard');
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateImageValidation',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateImageValidation',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateImageValidation',
          '0',
          err,
        ),
      );
    }
  };
};
export const updatePrescriptionInfo = (value, history, finalTag) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/updatePrescriptionInfoJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/updatePrescriptionInfo', value)
              .then((data) => {
                console.log(data, 'data');
                let message = data?.data?.message
                  ? data?.data?.message
                  : 'null';
                if (
                  data.status === 200 &&
                  (message &&
                    message.includes('error') &&
                    message.includes('Error')) == false
                ) {
                  dispatch({type: FETCH_SUCCESS});
                  let message = data?.data?.body?.status || '';
                  let digiL2Tag = data?.data?.body?.digitizationL2Tag || false;
                  dispatch({
                    type: DIGI_PRESCRIPTIONNUMBER,
                    payload: value?.prescriptionNumber,
                  });
                  dispatch({
                    type: DIGI_L1TAG,
                    payload: digiL2Tag,
                  });
                  if (message) {
                    if (message.includes('_')) {
                      dispatch({
                        type: DIGI_TOASTER,
                        payload: message.split('_')[1],
                      });
                      dispatch({
                        type: DIGI_SUCCESS_MSG,
                        payload: 'success',
                      });
                    }
                    if (message.includes(2)) {
                      // dispatch({
                      //   type: PRESCRIPTIONINFO_SUCCESS,
                      //   payload: 'Success',
                      // });
                      // dispatch({
                      //   type: SEND_AMAZON_SUCCESS_MSG,
                      //   payload: message.split('_')[1],
                      // });
                    }
                  }
                } else {
                  // history.push('/digitizationdashboard');
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updatePrescriptionInfo',
                    '0',
                    error,
                  ),
                );
              })
              .finally((data) => {
                dispatch({
                  type: DIGI_SUCCESS_MSG,
                  payload: 'error',
                });
              });
            // .finally((data) => {
            //   if (data === undefined || data === null) {
            //     if (finalTag) {
            //       history.push('/digitizationdashboard');
            //     }
            //   }
            // });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updatePrescriptionInfo',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updatePrescriptionInfo',
              '0',
              error,
            ),
          );
        })
        .finally((data) => {
          dispatch({
            type: DIGI_SUCCESS_MSG,
            payload: 'error',
          });
        });
    } catch (err) {
      dispatch({
        type: DIGI_SUCCESS_MSG,
        payload: 'error',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updatePrescriptionInfo',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Routes data
export const onGetDigiMedicineCategory = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/onGetMedCategoryJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/onGetMedCategory', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: DIGI_UOMLIST,
                    payload: data?.data?.body?.uomData,
                  });
                  dispatch({
                    type: DIGI_MEDCATEGORY,
                    payload: data?.data?.body?.medCategoryData,
                  });
                  dispatch({
                    type: DIGI_MED_SUGG,
                    payload:
                      data?.data?.body?.suggMed?.length > 0
                        ? data.data.body.suggMed
                        : [],
                  });
                  dispatch({
                    type: DIGI_L1TAG,
                    payload: data?.data?.body?.digitizationL2Tag
                      ? data.data.body.digitizationL2Tag
                      : '',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDigiMedicineCategory',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDigiMedicineCategory',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigiMedicineCategory',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigiMedicineCategory',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetDigitizationDashboardErxCountList = ({userCode, userId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/digitization/getDigitizationDashboardErxCountJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                jwtToken,
              };
              jwtAxios
                .post(
                  'v1/digitization/getDigitizationDashboardErxCount',
                  bodytoken,
                )
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    if (
                      data?.data?.body?.data &&
                      Object.keys(data.data.body.data).length > 0
                    ) {
                      //Get erx overallAmazonCompletedCount count and store local
                      dispatch({
                        type: TOTAL_DIGI_COMPLETED_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.completedDataCount
                          : '0',
                      });
                      let overallCompletedCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallDigiCompletedCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallDigiCompletedCount',
                                ),
                              )
                            : data.data.body.data
                            ? data.data.body.data.completedDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.completedDataCount
                          : '';
                      localStorage.setItem(
                        'overallDigiCompletedCount',
                        overallCompletedCount,
                      );

                      //Get erx overallAmazonPendingCount count and store local
                      let overallPendingCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallDigiPendingCount')
                            ? JSON.parse(
                                localStorage.getItem('overallDigiPendingCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.pendingDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.pendingDataCount
                          : '';

                      dispatch({
                        type: TOTAL_DIGI_PENDING_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.pendingDataCount
                          : '',
                      });

                      localStorage.setItem(
                        'overallDigiPendingCount',
                        overallPendingCount,
                      );

                      let overallFailureCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallDigiFailureCount')
                            ? JSON.parse(
                                localStorage.getItem('overallDigiFailureCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.failureDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.failureDataCount
                          : '';
                      dispatch({
                        type: TOTAL_DIGI_FAILURE_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.failureDataCount
                          : '',
                      });
                      localStorage.setItem(
                        'overallDigiFailureCount',
                        overallFailureCount,
                      );
                      //Get erx TOTAL_AMAZON_REJECT_COUNT count and store local
                      dispatch({
                        type: TOTAL_DIGI_REJECT_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.rejectedDataCount
                          : '',
                      });

                      let overallRejectCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallDigiRejectCount')
                            ? JSON.parse(
                                localStorage.getItem('overallDigiRejectCount'),
                              )
                            : data.data.body.data
                            ? data.data.body.data.rejectedDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.rejectedDataCount
                          : '';
                      localStorage.setItem(
                        'overallDigiRejectCount',
                        overallRejectCount,
                      );
                      //Get erx TOTAL_AMAZON_INPROGRESS_COUNT count and store local
                      dispatch({
                        type: TOTAL_DIGI_INPROGRESS_COUNT,
                        payload: data.data.body.data
                          ? data.data.body.data.inProgressDataCount
                          : '',
                      });
                      let overallInProgressCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallDigiInProgressCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallDigiInProgressCount',
                                ),
                              )
                            : data.data.body.data
                            ? data.data.body.data.inProgressDataCount
                            : ''
                          : data.data.body.data
                          ? data.data.body.data.inProgressDataCount
                          : '';
                      localStorage.setItem(
                        'overallDigiInProgressCount',
                        overallInProgressCount,
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDigitizationDashboardErxCountList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDigitizationDashboardErxCountList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigitizationDashboardErxCountList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigitizationDashboardErxCountList',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending List Data
export const onGetDigiDashboardPendingListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
      filterMobileNo,
    };

    try {
      //Call pending list Token  - Get Token
      jwtAxios
        .post('v1/digitization/getDigitizationRxPendingListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/digitization/getDigitizationRxPendingList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: DIGI_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DIGI_PENDING_DATA,
                      payload: getdata,
                    });
                    dispatch({
                      type: DIGI_PENDING_COUNT,
                      payload: data.data.body.data.totalCount,
                    });

                    //Clear Erxdatas every pending list loading
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDigiDashboardPendingListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDigiDashboardPendingListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigiDashboardPendingListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigiDashboardPendingListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetDigitizationInprogressData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
  filterMobileNo,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      filterMobileNo,
      session_id,
    };

    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/digitization/getDigitizationeRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/digitization/getDigitizationeRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatainprogres = data.data.body.data;
                    dispatch({
                      type: DIGI_INPROGRESSS_DATA,
                      payload: getdatainprogres,
                    });
                    dispatch({
                      type: DIGI_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DIGI_INPROGRESSS_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDigitizationInprogressData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDigitizationInprogressData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigitizationInprogressData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigitizationInprogressData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard inprogress List Data
export const onGetDigiCompeletdListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      filterMobileNo,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/digitization/getDigitizationeRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/digitization/getDigitizationeRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdatacompleted = data.data.body.data;
                    dispatch({
                      type: DIGI_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DIGI_COMPLETED_DATA,
                      payload: getdatacompleted,
                    });
                    dispatch({
                      type: DIGI_COMPLETED_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDigiCompeletdListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDigiCompeletdListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigiCompeletdListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigiCompeletdListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard reject List Data
export const onGetDigiRejectListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  filterRejectId,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterMobileNo,
      filterRejectId,
      filterVendor,
      session_id,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/digitization/getDigitizationeRxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post('v1/digitization/getDigitizationeRxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdataReject = data.data.body.data;
                    dispatch({
                      type: DIGI_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DIGI_REJECT_DATA,
                      payload: getdataReject,
                    });
                    dispatch({
                      type: DIGI_REJECT_COUNT,
                      payload: getdataReject && getdataReject.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDigiRejectListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDigiRejectListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigiRejectListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigiRejectListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending List Data
export const onGetDigitizationSendFailureListData = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  filterMobileNo,
  session_id,
  retryTag,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body1 = {retryTag};
    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
      filterMobileNo,
    };

    try {
      //Call pending list Token  - Get Token
      jwtAxios
        .post('v1/digitization/getDigitizationSendFailureListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                userId,
                status,
                offset,
                limit,
                jwtToken,
                session_id,
              };
              jwtAxios
                .post(
                  'v1/digitization/getDigitizationSendFailureList',
                  bodytoken,
                )
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: DIGI_LIST_RESULT,
                      payload: true,
                    });
                    dispatch({
                      type: DIGI_FAILURE_DATA,
                      payload: getdata,
                    });
                    dispatch({
                      type: DIGI_FAILURE_COUNT,
                      payload: data.data.body.data.totalCount,
                    });

                    //Clear Erxdatas every pending list loading
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDashboardPendingListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDashboardPendingListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDashboardPendingListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDashboardPendingListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

export const sendFailureIngresstoAmazon = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/sendDigitizationIngresstoAmazonJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/sendDigitizationIngresstoAmazon', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEND_AMAZON_SUCCESS_MSG,
                    payload: 'eRx Submitted Successfully',
                  });
                  let passdata = {
                    userCode: value.userCode,
                    userId: '',
                    status: '4',
                    offset: 0,
                    limit: 10,
                    filtercheckInStartDate: '',
                    filtercheckInEndDate: '',
                    filterfullfillmentStartDate: '',
                    filterfullfillmentEndDate: '',
                    filterpatName: '',
                    filterMedia: '',
                    filterService: [],
                    filterVendor: '',
                    session_id: value.sessionId,
                    retryTag: true,
                  };
                  dispatch(onGetDigitizationSendFailureListData(passdata));
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'sendFailureIngresstoAmazon',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendFailureIngresstoAmazon',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendFailureIngresstoAmazon',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendFailureIngresstoAmazon',
          '0',
          err,
        ),
      );
    }
  };
};
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join('-');
}

export const sendIngresstoAmazon = (value, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/sendDigitizationIngresstoAmazonJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/sendDigitizationIngresstoAmazon', value)
              .then((data) => {
                if (data.status === 200) {
                  console.log(data, 'data res');
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEND_AMAZON_SUCCESS_MSG,
                    payload: 'eRx Submitted Successfully',
                  });
                  dispatch({
                    type: DIGI_FINAL_SUCCESS,
                    payload: 'Success',
                  });
                  let passdata = {
                    userCode: value.userCode,
                    userId: '',
                    status: '0',
                    offset: 0,
                    limit: 10,
                    filtercheckInStartDate: '',
                    filtercheckInEndDate: '',
                    filterfullfillmentStartDate: '',
                    filterfullfillmentEndDate: '',
                    filterpatName: '',
                    filterMedia: '',
                    filterService: [],
                    filterVendor: '',
                    session_id: value.sessionId,
                    retryTag: true,
                  };
                  let pass_data = {
                    userCode: value.userCode,
                    userId: '',
                    status: '3',
                    offset: 0,
                    limit: 10,
                    filtercheckInStartDate: '',
                    filtercheckInEndDate: '',
                    filterfullfillmentStartDate: formatDate(new Date()),
                    filterfullfillmentEndDate: formatDate(new Date()),
                    filterpatName: '',
                    filterMedia: '',
                    filterService: [],
                    filterVendor: '',
                    session_id: value.sessionId,
                    retryTag: true,
                  };
                  dispatch(onGetDigiDashboardPendingListData(passdata));
                  dispatch(onGetDigitizationSendFailureListData(pass_data));
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'sendFailureIngresstoAmazon',
                    '0',
                    error,
                  ),
                );
              })
              .finally((data) => {
                if (data === undefined || data === null) {
                  {
                    history.push('/digitizationdashboard');
                  }
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendFailureIngresstoAmazon',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendFailureIngresstoAmazon',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendFailureIngresstoAmazon',
          '0',
          err,
        ),
      );
    }
  };
};
export const sendIngresstoL2operator = (value, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/sendDigitizationtoL2Jwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/sendDigitizationtoL2', value)
              .then((data) => {
                if (data.status === 200) {
                  console.log(data, 'data res');
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEND_AMAZON_SUCCESS_MSG,
                    payload: 'eRx Submitted Successfully',
                  });
                  dispatch({
                    type: DIGI_FINAL_SUCCESS,
                    payload: 'Success',
                  });
                  let passdata = {
                    userCode: value.userCode,
                    userId: '',
                    status: '0',
                    offset: 0,
                    limit: 10,
                    filtercheckInStartDate: '',
                    filtercheckInEndDate: '',
                    filterfullfillmentStartDate: '',
                    filterfullfillmentEndDate: '',
                    filterpatName: '',
                    filterMedia: '',
                    filterService: [],
                    filterVendor: '',
                    session_id: value.sessionId,
                    retryTag: true,
                  };
                  let pass_data = {
                    userCode: value.userCode,
                    userId: '',
                    status: '3',
                    offset: 0,
                    limit: 10,
                    filtercheckInStartDate: '',
                    filtercheckInEndDate: '',
                    filterfullfillmentStartDate: formatDate(new Date()),
                    filterfullfillmentEndDate: formatDate(new Date()),
                    filterpatName: '',
                    filterMedia: '',
                    filterService: [],
                    filterVendor: '',
                    session_id: value.sessionId,
                    retryTag: true,
                  };
                  dispatch(onGetDigiDashboardPendingListData(passdata));
                  dispatch(onGetDigitizationSendFailureListData(pass_data));
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'sendIngresstoL2operator',
                    '0',
                    error,
                  ),
                );
              })
              .finally((data) => {
                if (data === undefined || data === null) {
                  {
                    history.push('/digitizationdashboard');
                  }
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'sendIngresstoL2operator',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'sendIngresstoL2operator',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'sendIngresstoL2operator',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetDigiDoctorInfo = (value) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/digitization/getDoctorDigitizationJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/digitization/getDoctorDigitization', value)
              .then((data) => {
                if (data.data.status === 200) {
                  // dispatch({type: FETCH_SUCCESS});

                  // dispatch({
                  //   type: GET_DOCTOR_EDITDATA,
                  //   payload: data.data.body,
                  // });
                  // dispatch({
                  //   type: GET_DOCTOR_SUGGESTION_HOSPITAL,
                  //   payload: data?.data?.body?.suggestion_hospitals || [],
                  // });
                  if (data && data.data && data.data.body.doctorhospitals) {
                    dispatch({
                      type: DIGITIZATION_DOCTORS_SUGGESTIONS,
                      payload: data.data.body.doctorhospitals,
                    });
                  }
                  dispatch({
                    type: DIGITIZATION_DOCTOR_AUTOFILL,
                    payload: data.data.body.autoFill,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDigiDoctorInfo',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDoctorInfo',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigiDoctorInfo',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigiDoctorInfo',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetNerDigiHospitalSuggestions = (value) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get the data
      jwtAxios
        .post('v1/digitization/getNerHospitalSuggestions', value)
        .then((data) => {
          // dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_NER_HOSPITALS,
            payload: data.data.body.suggestionnerhospitalList,
          });
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetNerDigiHospitalSuggestions',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetNerDigiHospitalSuggestions',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetDigitizationnewMed = (value) => {
  return (dispatch) => {
    try {
      jwtAxios
        .post('v1/pateRxMgmt/searchmedicineNamejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/searchmedicineName', value)
              .then((data) => {
                if (data.status === 200) {
                  let options = data?.data?.body ? data.data.body : [];
                  if (options && options.length > 0) {
                    let newOption = options.map((item) => {
                      return {
                        medicineName: item.medicineAmaName
                          ? item.medicineAmaName
                          : '',
                      };
                    });
                    dispatch({
                      type: DIGI_NEWMEDICINE_DATA,
                      payload: newOption,
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDigitizationnewMed',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDigitizationnewMed',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigitizationnewMed',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigitizationnewMed',
          '0',
          err,
        ),
      );
    }
  };
};

export const insertDigiMedicineData = (value, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/insertMedicineDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/insertMedicineData', value)
              .then((data) => {
                console.log(data, 'data');
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: DIGI_MED_SUGG,
                    payload:
                      data?.data?.body?.suggMed?.length > 0
                        ? data.data.body.suggMed
                        : [],
                  });
                  dispatch({
                    type: DIGI_MEDINSERT_SUCCESS,
                    payload: 'Success',
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'insertDigiMedicineData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'insertDigiMedicineData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertDigiMedicineData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'insertDigiMedicineData',
          '0',
          err,
        ),
      );
    }
  };
};

export const deleteDigiMedicineData = (value, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/deleteMedicineDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/deleteMedicineData', value)
              .then((data) => {
                console.log(data, 'data');
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: DIGI_MEDINSERT_SUCCESS,
                    payload: 'Success',
                  });
                  dispatch({
                    type: DIGI_MED_SUGG,
                    payload:
                      data?.data?.body?.suggMed?.length > 0
                        ? data.data.body.suggMed
                        : [],
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'deleteDigiMedicineData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'deleteDigiMedicineData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'deleteDigiMedicineData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'deleteDigiMedicineData',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetDigitizationSettings = (value) => {
  return (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/digitization/getDigitizationSettingsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/getDigitizationSettings', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: DIGI_GENERAL_SETTINGS,
                    payload: data?.data?.body
                      ? data.data.body
                      : '',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDigitizationSettings',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDigitizationSettings',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigitizationSettings',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigitizationSettings',
          '0',
          err,
        ),
      );
    }
  };
};

export const updateDigitizationSettings = (value) => {
  return (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/digitization/getDigitizationSettingsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/getDigitizationSettings', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: DIGI_SETTINGS_UPDATE,
                    payload: 'Success',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDigitizationSettings',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDigitizationSettings',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDigitizationSettings',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDigitizationSettings',
          '0',
          err,
        ),
      );
    }
  };
};

export const updateVitalSettings = (value) => {
  return (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/digitization/getvitalSettingsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/getvitalSettings', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  console.log(data, data?.data?.body, 'data');
                  dispatch({
                    type: VITALS_SETTINGS_UPDATE,
                    payload: data?.data?.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateVitalSettings',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateVitalSettings',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateVitalSettings',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateVitalSettings',
          '0',
          err,
        ),
      );
    }
  };
};

export const insertAssignAuditLog = (value) => {
  return (dispatch) => {
    try {
      jwtAxios
        .post('v1/digitization/insertDigitizationAssignDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/insertDigitizationAssignData', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: DIGI_DOSAGE_OPTIONS,
                    payload:
                      data?.data?.body?.dosageoptions &&
                      data?.data?.body?.dosageoptions?.length > 0
                        ? data?.data?.body?.dosageoptions
                        : [],
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'insertAssignAuditLog',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'insertAssignAuditLog',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertAssignAuditLog',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'insertAssignAuditLog',
          '0',
          err,
        ),
      );
    }
  };
};

//get erxNotes
export const retryChatGptDigitizationOCR = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/retryOCR', value)
        .then((data) => {
          try {
            dispatch({type: FETCH_SUCCESS});
            dispatch(
              onRefreshOCRData({
                erxId: value?.erxId?.toString(),
                userCode: value?.userCode,
                userId: value?.userId,
              }),
            );
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', err),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', error),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'retryOCR', '0', err),
      );
    }
  };
};

//get erxNotes
export const onRefreshOCRData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/refreshOCRData', value)
        .then((data) => {
          if (data.status === 200) {
            const getdata = data.data.body;
            dispatch({type: FETCH_SUCCESS});
            if (getdata && getdata['status'] === 'success') {
              dispatch({
                type: DIGI_PRESCRIPTION_IMAGES,
                payload: getdata['prescriptionList'] || [],
              });
              //Image verification
              if (
                getdata['segregationStatus'] &&
                getdata['segregationStatus'] == true
              ) {
                if (
                  getdata['prescriptionList'] &&
                  getdata['prescriptionList'].length > 0
                ) {
                  let FinalArr = [];
                  let groupByPresNo = getdata['prescriptionList'].reduce(
                    function (rv, x) {
                      (rv[x['prescriptionNumber']] =
                        rv[x['prescriptionNumber']] || []).push(x);
                      return rv;
                    },
                    {},
                  );
                  //frame prescription array
                  Object.values(groupByPresNo).forEach((items) => {
                    let itemsArr = [];
                    for (let j = 0; j < items.length; j++) {
                      itemsArr.push(items[j]['prescriptionImgUrl']);
                    }

                    FinalArr.push({url: itemsArr});
                  });

                  dispatch({
                    type: DIGI_PRESCRIPTION_DATA,
                    payload: FinalArr,
                  });
                }
              }

              dispatch({
                type: PRESCRIPTION_OCR_DATA,
                payload:
                  data?.data?.body?.pres_Data &&
                  data?.data?.body?.pres_Data?.length > 0
                    ? data.data.body.pres_Data
                    : [],
              });
            }
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onRefreshOCRData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onRefreshOCRData',
          '0',
          err,
        ),
      );
    }
  };
};
