/************
 * Developed by : Shiva Software Solutions
 * Date : 15-03-2023
 * Descriptions : vendor credentials api calling
 ************/
import {
  GET_CLIENT_MASTERS,
  GET_OPERATOR_LIST,
  FETCH_ERROR,
  FETCH_SUCCESS,
  FETCH_START,
  GET_CAMERA_LIST,
  GET_CAMERA_LIST_EXIST,
  GET_BUZZER_LIST,
  GET_MAPPED_AGENTS,
  GET_MAPPED_AGENTS_COUNT,
  GET_DOCTOR_ADDEDLIST,
  GET_AVAILABLE_CAMERALIST,
  UPDATE_SUCCESS_ROOM,
  GET_ROOMS_LIST,
  GET_ROOMS_LIST_COUNT,
  GET_CLIENT_CATEGORY,
  CLIENT_USER_CAT,
  UPDATE_AGENT_MSG,
} from '../../shared/constants/ActionTypes';
import {useSelector} from 'react-redux';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'userManagement.js';
//Get client list data
export const onGetClientList = ({
  userId,
  userCode,
  getMapped,
  agentId = '',
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, getMapped, agentId};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getClientMastersListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, jwtToken, userCode, agentId};
              jwtAxios
                .post('v1/cameraDash/getClientMastersList', bodytoken)
                .then((data) => {
                  console.log(data, 'List');
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_CLIENT_MASTERS,
                      payload: data.data.body?.data?.clientDetails || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

//Get operators list data
export const onGetOperatorList = ({userId, userCode, getMapped}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, getMapped};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getOperatorMastersListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, jwtToken, userCode};
              jwtAxios
                .post('v1/cameraDash/getOperatorMastersList', bodytoken)
                .then((data) => {
                  console.log(data, 'List');
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_OPERATOR_LIST,
                      payload: data.data.body?.operatorList || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

//Get client list data
export const saveAgentMapping = (req) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = req;
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/saveAgentMappingJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              req['jwtToken'] = jwtToken;
              const bodytoken = req;
              jwtAxios
                .post('v1/cameraDash/saveAgentMapping', bodytoken)
                .then((data) => {
                  console.log(data, 'List');
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    if (data.data.body) {
                      dispatch({
                        type: UPDATE_AGENT_MSG,
                        payload: data.data.body,
                      });

                      if (
                        data.data.body == 'success' ||
                        data.data.body.includes('Delete:')
                      )
                        dispatch(
                          getAgentMapping({
                            clientId: req.clientId,
                            userCode: req.userCode,
                            limit: 10,
                            offset: 0,
                          }),
                        );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

//Get client list data
export const getAgentMapping = (req) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = req;
    console.log(req, 'reeeeeq');
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getAgentMappingJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              req['jwtToken'] = jwtToken;
              const bodytoken = req;
              jwtAxios
                .post('v1/cameraDash/getAgentMapping', bodytoken)
                .then((data) => {
                  console.log(data, 'List');
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_MAPPED_AGENTS,
                      payload: data?.data?.body?.list || [],
                    });
                    dispatch({
                      type: GET_MAPPED_AGENTS_COUNT,
                      payload: data?.data?.body?.listCount || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

//Get client list data
export const getDocList = (req) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = req;
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/getClientDoctorsListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              req['jwtToken'] = jwtToken;
              const bodytoken = req;
              jwtAxios
                .post('v1/vendor/getClientDoctorsList', bodytoken)
                .then((data) => {
                  console.log(data, 'List');
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_DOCTOR_ADDEDLIST,
                      payload: data.data.body || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

//Get client list data
export const getAvailableCameraList = (req) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = req;
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getUnallocatedCameraJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              req['jwtToken'] = jwtToken;
              const bodytoken = req;
              jwtAxios
                .post('v1/cameraDash/getUnallocatedCamera', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_AVAILABLE_CAMERALIST,
                      payload: data.data.body || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

//Get client list data
export const saveRoomDtls = (req) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = req;
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/saveRoomDetailsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              req['jwtToken'] = jwtToken;
              const bodytoken = req;
              jwtAxios
                .post('v1/cameraDash/saveRoomDetails', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: UPDATE_SUCCESS_ROOM,
                      payload: data.data.body || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

//Get client list data
export const getRoomDtls = (req) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = req;
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getFloorRooms', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: GET_ROOMS_LIST,
                payload: data?.data?.body?.list || [],
              });
              dispatch({
                type: GET_ROOMS_LIST_COUNT,
                payload: data?.data?.body?.listCount
                  ? data?.data?.body?.listCount
                  : 0,
              });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

export const onGetCameraDashboard = ({
  userId,
  userCode,
  hospitalId,
  clientId,
  agentId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, clientId, hospitalId, agentId};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getCameraListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              if (data?.data?.body['token'] && data.data.body['token']) {
                const jwtToken = data.data.body['token'];
                const bodytoken = {
                  userId,
                  userCode,
                  clientId,
                  hospitalId,
                  agentId,
                  jwtToken,
                };
                jwtAxios
                  .post('v1/cameraDash/getCameraList', bodytoken)
                  .then((data) => {
                    console.log(data, 'List');
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: GET_CAMERA_LIST,
                        payload: data.data.body?.data || [],
                      });
                      dispatch({
                        type: GET_CAMERA_LIST_EXIST,
                        payload: 'success',
                      });
                    } else {
                      dispatch({
                        type: GET_CAMERA_LIST_EXIST,
                        payload: 'failure',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({
                      type: GET_CAMERA_LIST_EXIST,
                      payload: 'failure',
                    });
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onGetCameraDashboard',
                        '0',
                        error,
                      ),
                    );
                  });
              } else {
                dispatch({
                  type: GET_CAMERA_LIST_EXIST,
                  payload: 'failure',
                });
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            } catch (err) {
              dispatch({
                type: GET_CAMERA_LIST_EXIST,
                payload: 'failure',
              });
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetCameraDashboard',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: GET_CAMERA_LIST_EXIST,
              payload: 'failure',
            });
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_CAMERA_LIST_EXIST,
            payload: 'failure',
          });
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetCameraDashboard',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: GET_CAMERA_LIST_EXIST,
        payload: 'failure',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetCameraDashboard',
          '0',
          err,
        ),
      );
    }
  };
};

//Get client list data
export const getClientCategoryLists = (req) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = req;
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getClientCategory', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              dispatch({type: FETCH_SUCCESS});
              if (data?.data?.body?.category)
                dispatch({
                  type: GET_CLIENT_CATEGORY,
                  payload: data?.data?.body?.category || [],
                });
                let list= data?.data?.body?.category || []
                if(list?.length > 0){
                  const result = list.filter((item) => item.categoryId != 8);
                  dispatch({
                    type: CLIENT_USER_CAT,
                    payload: result,
                  });
                }
               
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

/* Async Action buzzer using - Sockets													   */
export const loadInitialDataSocket = (socket) => {
  return (dispatch) => {
    socket.on('InitialBuzzer', (res) => {
      console.log(res, 'called ,,..,.');
      dispatch({
        type: GET_BUZZER_LIST,
        payload: res,
      });
    });
  };
};
