
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';
//Route config for eRx details
export const traumsDetailsConfigs = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/traumaDetails',
        component: React.lazy(() => import('./SimpleTabs')),
      },
    ],
  },
];
