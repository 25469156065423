import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import HorizontalSingle from './HorizontalSingle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import {useSelector} from 'react-redux';
import {useEffect, useState, useRef} from 'react';
import routesConfig from '../../../../modules/routesConfig';
import amazonrouteConfig from '../../../../modules/amazonRouteConfig';
import digitizationRouteConfig from '../../../../modules/digitizationRouteConfig';
import traumaRouteConfig from '../../../../modules/traumaRouteConfig';
import Box from '@material-ui/core/Box';
import {authRole} from '../../../../shared/constants/AppConst';

const HorizontalNav = () => {
  const LoginUser = useSelector(({auth}) => auth.user);
  const [digitization, setDigitization] = React.useState(false);
  const [routeConfigList, setRouteConfigList] = React.useState([]);
  const cameraDash = useSelector(
    ({dashboard}) => dashboard.cameraPermissions,
  );
  useEffect(() => {
    if (LoginUser != null) {
      let array = LoginUser?.userPermissions?.permission || [];
      let routingConfig = routesConfig;
      if (array.includes(11) && array.includes(13)) {
        routingConfig = [...digitizationRouteConfig,...traumaRouteConfig];
        setDigitization(true);
      }else if(array.includes(13))
        {
          routingConfig = routesConfig;
        } 
        else if(array.includes(11))
        {
          routingConfig = [...digitizationRouteConfig];
          setDigitization(true);
        }
      else {
        routingConfig = routesConfig;
      }

      let length = routingConfig.length - 1;
      let config = [...routingConfig];
      let config1 = [...routingConfig];
      let routing_Config = [...routingConfig];

      if (cameraDash) {
        let cameraRoutes = [
          {
            id: 'camera',
            title: 'Camera Dashboard',
            messageId: 'sidebar.camera',
            type: 'single',
            icon: 'camera',
            auth: authRole.dashboardRoles,
            url: '/dashboard-camera',
          },
        ];
        let indexRoute = routingConfig.findIndex(
          (ele) => ele.id == 'searchPatient',
        );
        if (indexRoute != -1) {
          routing_Config = [
            ...config1.splice(0, indexRoute),
            ...cameraRoutes,
            ...config.splice(indexRoute, length),
          ];
        }
      }
      setRouteConfigList(routing_Config);
      if (LoginUser.roleId == 4 && LoginUser.userCategory == 1) {
        if (digitization == true) {
          amazonrouteConfig.push({
            id: 'digitizationdashboard',
            title: 'Digitization Dashboard',
            messageId: 'sidebar.AdminDigitizationdashboard',
            type: 'single',
            icon: 'memory',
            auth: authRole.dashboardRoles,
            url: '/digitizationdashboard',
          });
        } else {
          setRouteConfigList(amazonrouteConfig);
        }
      }
    }
  }, [LoginUser]);
  return (
    <>
      {routeConfigList.length > 0 && (
        <List className='navbarNav'>
          {routeConfigList.map((item) => (
            <React.Fragment key={item.id}>
              {item.type === 'single' &&
                item.auth.includes(LoginUser.roleId) && (
                  <HorizontalSingle item={item} nestedLevel={0} />
                )}

              {item.type === 'group' &&
                item.auth.includes(LoginUser.roleId) && (
                  <HorizontalGroup item={item} nestedLevel={0} />
                )}

              {item.type === 'collapse' && (
                <HorizontalCollapse item={item} nestedLevel={0} />
              )}

              {item.type === 'item' && (
                <HorizontalItem item={item} nestedLevel={0} />
              )}

              {item.type === 'divider' && (
                <Box my={5} clone>
                  <Divider />
                </Box>
              )}
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
};

export default HorizontalNav;
