/************
 * Developed by : Shiva Software Solutions
 * Date : 21-12-2021
 * Descriptions : Masters api calling
 ************/
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  UPDATE_FLOOR_DET,
  GET_SYMPTOM_MASTER_DATA,
  TRAUMA_CLIENT_TAG,
  CLIENT_MASTER_BILLING_DETAILS,
  SYMPTOM_SUCCESS,
  NEW_SYMPTOM,
  GET_HOSPITAL_FLOOR_LIST,
  GET_DIAGNOSIS_MASTER_DATA,
  DIAGNOSIS_SUCCESS,
  GET_INVESTIGATION_MASTER_DATA,
  INVESTIGATION_SUCCESS,
  ALLERGY_SUCCESS,
  GET_ALLERGY_MASTER_DATA,
  GET_DISEASE_MASTER_DATA,
  GET_DEPARTMENT_MASTER_DATA,
  GET_ALL_DEPARTMENT_MASTER_DATA,
  DISEASE_SUCCESS,
  GET_PROCEDURE_MASTER_DATA,
  PROCEDURE_SUCCESS,
  NEW_ALLERGY,
  NEW_DIAGNOSIS,
  NEW_DISEASE,
  NEW_PROCEDURE,
  SHOW_MERGED_HOSPITALS_MASTER,
  NEW_INVESTIGATION,
  SETTING_SUCCESS,
  GET_DOCTOR_MASTER,
  SAVE_MERGED_HOSPITALS_MASTER,
  GET_DOCTOR_DATA_SUCCESS,
  GET_DOCTOR_DATA,
  UPDATE_DOCTOR_SUCCESS_MSG,
  GET_CLIENT_MASTER_DATA,
  MEDIA_MASTER,
  GET_CLIENT_HOSPITAL_DATA,
  GET_CLIENT_DEPARTMENT_DATA,
  GET_CLIENT_DATA,
  GET_CLIENT_MEDIA_DATA,
  UPDATE_CLIENT_HOSPITAl,
  OPERATOR_REPORT,
  OPREPORT_ERROR,
  GET_APPROVED_DOCTORS,
  UPDATE_HOS_DEPT,
  UPDATE_DOC_SCEQ,
  REMOVE_HOS_DEPT,
  GET_HOSPITAL_FLOOR,
  GET_CLIENT_DOCTOR,
  GET_CLIENT_DOCTOR_COUNT,
  GET_FLOOR_NAME,
  GET_CONSULTING_TIME,
  SAVE_CAMERA_INFO,
  LIST_CAMERA_INFO,
  GET_CLIENT_NAME,
  GET_CLIENT_ID,
  GET_CLIENT_PORTAL_INFO,
  GET_TRAINED_DATA,
  GET_LANGUAGE_MASTER_DATA,
  GET_LANGUAGE_LABELS,
  GET_LANGUAGES,
  LANGUAGES_SUCCESS,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {getDocList, GetVendorCredentialdata} from '../../redux/actions';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Master.js';
//get symptom master list using jwt token
export const GetMastersListData = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/getMastersListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/getMastersList', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    if (body.mastertype === 'symptoms') {
                      dispatch({
                        type: GET_SYMPTOM_MASTER_DATA,
                        payload: data.data.body,
                      });
                    }
                    if (body.mastertype === 'procedures') {
                      dispatch({
                        type: GET_PROCEDURE_MASTER_DATA,
                        payload: data.data.body,
                      });
                    }
                    if (body.mastertype === 'diagnosis') {
                      dispatch({
                        type: GET_DIAGNOSIS_MASTER_DATA,
                        payload: data.data.body,
                      });
                    }
                    if (body.mastertype === 'investigation') {
                      dispatch({
                        type: GET_INVESTIGATION_MASTER_DATA,
                        payload: data.data.body,
                      });
                    }
                    if (body.mastertype === 'allergy') {
                      dispatch({
                        type: GET_ALLERGY_MASTER_DATA,
                        payload: data.data.body,
                      });
                    }
                    if (body.mastertype === 'disease') {
                      dispatch({
                        type: GET_DISEASE_MASTER_DATA,
                        payload: data.data.body,
                      });
                    }
                    if (body.mastertype === 'department') {
                      if (body['limit'] == 0) {
                        dispatch({
                          type: GET_ALL_DEPARTMENT_MASTER_DATA,
                          payload: data.data.body,
                        });
                      } else
                        dispatch({
                          type: GET_DEPARTMENT_MASTER_DATA,
                          payload: data.data.body,
                        });
                    }
                    if (body.mastertype === 'languages') {
                      if (data.data.body)
                        dispatch({
                          type: GET_LANGUAGES,
                          payload: data.data.body?.resultData,
                        });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'GetMastersListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'GetMastersListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GetMastersListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};
//SaveAndUpdateSymptoms
export const SaveAndUpdateSymptoms = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/SaveandUpdateSymptomjwt', body)
        .then((data) => {
          if (data.status === 200) {
            if (data.data.message) {
              if (data.data.message.includes('Duplicate')) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: SYMPTOM_SUCCESS,
                  payload: 'duplicate',
                });
                dispatch({
                  type: NEW_SYMPTOM,
                  payload: '',
                });
              }
            } else {
              //Call symptom User list data - Get symptom list data using token
              try {
                body['jwtToken'] = data.data.body['token'];
                jwtAxios
                  .post('v1/master/SaveandUpdateSymptom', body)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      if (
                        data.data &&
                        data.data.body &&
                        data.data.body.symptomps
                      ) {
                        dispatch({
                          type: NEW_SYMPTOM,
                          payload: data.data.body.symptomps,
                        });
                      }
                      dispatch({
                        type: SYMPTOM_SUCCESS,
                        payload: 'success',
                      });
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: SYMPTOM_SUCCESS,
                        payload: 'failure',
                      });
                      dispatch({
                        type: NEW_SYMPTOM,
                        payload: '',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onGetDoctorMaster',
                        '0',
                        error,
                      ),
                    );
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDoctorMaster',
                    '0',
                    err,
                  ),
                );
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};
//SaveAndUpdateDiagnosis
export const SaveAndUpdateDiagnosis = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/SaveandUpdateDiagnosisjwt', body)
        .then((data) => {
          if (data.status === 200) {
            if (data.data.message) {
              if (data.data.message.includes('Duplicate')) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: DIAGNOSIS_SUCCESS,
                  payload: 'duplicate',
                });
                dispatch({
                  type: NEW_DIAGNOSIS,
                  payload: '',
                });
              }
            } else {
              //Call symptom User list data - Get symptom list data using token
              try {
                body['jwtToken'] = data.data.body['token'];
                jwtAxios
                  .post('v1/master/SaveandUpdateDiagnosis', body)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: DIAGNOSIS_SUCCESS,
                        payload: 'success',
                      });
                      if (
                        data.data &&
                        data.data.body &&
                        data.data.body.diagnosis
                      ) {
                        dispatch({
                          type: NEW_DIAGNOSIS,
                          payload: data.data.body.diagnosis,
                        });
                      }
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: DIAGNOSIS_SUCCESS,
                        payload: 'failure',
                      });
                      dispatch({
                        type: NEW_DIAGNOSIS,
                        payload: '',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onGetDoctorMaster',
                        '0',
                        error,
                      ),
                    );
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDoctorMaster',
                    '0',
                    err,
                  ),
                );
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};

//SaveAndUpdateInvestigation
export const SaveAndUpdateInvestigation = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Investigation list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/SaveandUpdateInvestigationjwt', body)
        .then((data) => {
          if (data.status === 200) {
            if (data.data.message) {
              if (data.data.message.includes('Duplicate')) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: INVESTIGATION_SUCCESS,
                  payload: 'duplicate',
                });
                dispatch({
                  type: NEW_INVESTIGATION,
                  payload: '',
                });
              }
            } else {
              //Call Investigation  insert data
              try {
                body['jwtToken'] = data.data.body['token'];
                jwtAxios
                  .post('v1/master/SaveandUpdateInvestigation', body)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: INVESTIGATION_SUCCESS,
                        payload: 'success',
                      });
                      if (
                        data.data &&
                        data.data.body &&
                        data.data.body.investigation
                      ) {
                        dispatch({
                          type: NEW_INVESTIGATION,
                          payload: data.data.body.investigation,
                        });
                      }
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: INVESTIGATION_SUCCESS,
                        payload: 'failure',
                      });
                      dispatch({
                        type: NEW_INVESTIGATION,
                        payload: '',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'SaveAndUpdateInvestigation',
                        '0',
                        error,
                      ),
                    );
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'SaveAndUpdateInvestigation',
                    '0',
                    err,
                  ),
                );
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'SaveAndUpdateInvestigation',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'SaveAndUpdateInvestigation',
          '0',
          err,
        ),
      );
    }
  };
};

//SaveAndUpdateAllergy
export const SaveAndUpdateAllergy = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Allergy list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/SaveandUpdateAllergyjwt', body)
        .then((data) => {
          if (data.status === 200) {
            if (data.data.message) {
              if (data.data.message.includes('Duplicate')) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: ALLERGY_SUCCESS,
                  payload: 'duplicate',
                });
                dispatch({
                  type: NEW_ALLERGY,
                  payload: '',
                });
              }
            } else {
              //Call Allergy  insert data
              try {
                body['jwtToken'] = data.data.body['token'];
                jwtAxios
                  .post('v1/master/SaveandUpdateAllergy', body)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: ALLERGY_SUCCESS,
                        payload: 'success',
                      });
                      if (
                        data.data &&
                        data.data.body &&
                        data.data.body.allergy
                      ) {
                        dispatch({
                          type: NEW_ALLERGY,
                          payload: data.data.body.allergy,
                        });
                      }
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: ALLERGY_SUCCESS,
                        payload: 'failure',
                      });
                      dispatch({
                        type: NEW_ALLERGY,
                        payload: '',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'SaveAndUpdateAllergy',
                        '0',
                        error,
                      ),
                    );
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'SaveAndUpdateAllergy',
                    '0',
                    err,
                  ),
                );
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'SaveAndUpdateAllergy',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'SaveAndUpdateAllergy',
          '0',
          err,
        ),
      );
    }
  };
};

//SaveAndUpdateDisease
export const SaveAndUpdateDisease = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Disease list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/SaveandUpdateDiseasejwt', body)
        .then((data) => {
          if (data.status === 200) {
            if (data.data.message) {
              if (data.data.message.includes('Duplicate')) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: DISEASE_SUCCESS,
                  payload: 'duplicate',
                });
                dispatch({
                  type: NEW_DISEASE,
                  payload: '',
                });
              }
            } else {
              //Call Allergy  insert data
              try {
                body['jwtToken'] = data.data.body['token'];
                jwtAxios
                  .post('v1/master/SaveandUpdateDisease', body)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: DISEASE_SUCCESS,
                        payload: 'success',
                      });
                      if (
                        data.data &&
                        data.data.body &&
                        data.data.body.disease
                      ) {
                        dispatch({
                          type: NEW_DISEASE,
                          payload: data.data.body.disease,
                        });
                      }
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: DISEASE_SUCCESS,
                        payload: 'failure',
                      });
                      dispatch({
                        type: NEW_DISEASE,
                        payload: '',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'SaveAndUpdateDisease',
                        '0',
                        error,
                      ),
                    );
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'SaveAndUpdateDisease',
                    '0',
                    err,
                  ),
                );
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'SaveAndUpdateDisease',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'SaveAndUpdateDisease',
          '0',
          err,
        ),
      );
    }
  };
};
//save and update procedure
export const SaveAndUpdateProcedures = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call procedure list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/SaveandUpdateProcedurejwt', body)
        .then((data) => {
          if (data.status === 200) {
            // console.log(data);
            if (data.data.message) {
              if (data.data.message.includes('Duplicate')) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: PROCEDURE_SUCCESS,
                  payload: 'duplicate',
                });
                dispatch({
                  type: NEW_PROCEDURE,
                  payload: '',
                });
              }
            } else {
              //Call symptom User list data - Get procedure list data using token
              try {
                body['jwtToken'] = data.data.body['token'];
                jwtAxios
                  .post('v1/master/SaveandUpdateProcedure', body)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: PROCEDURE_SUCCESS,
                        payload: 'success',
                      });
                      if (
                        data.data &&
                        data.data.body &&
                        data.data.body.procedure
                      ) {
                        dispatch({
                          type: NEW_PROCEDURE,
                          payload: data.data.body.procedure,
                        });
                      }
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: PROCEDURE_SUCCESS,
                        payload: 'failure',
                      });
                      dispatch({
                        type: NEW_PROCEDURE,
                        payload: '',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'SaveAndUpdateProcedures',
                        '0',
                        error,
                      ),
                    );
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'SaveAndUpdateProcedures',
                    '0',
                    err,
                  ),
                );
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'SaveAndUpdateProcedures',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'SaveAndUpdateProcedures',
          '0',
          err,
        ),
      );
    }
  };
};

//onSaveSettings
export const onSaveSettings = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      try {
        jwtAxios
          .post('v1/master/SaveSettings', body)
          .then((data) => {
            dispatch({type: FETCH_SUCCESS});
            if (data && data.status && data.status === 200) {
              console.log(data, 'data serial');
            }
            dispatch({
              type: SETTING_SUCCESS,
              payload: data.data.message,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSaveSettings',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({type: FETCH_ERROR, payload: err.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onSaveSettings',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({type: FETCH_ERROR, payload: err.message});
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSaveSettings', '0', err),
      );
    }
  };
};

//save OCRformat
export const onSaveFormatOCR = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      try {
        jwtAxios
          .post('v1/pateRxMgmt/onSaveFormatOCR', body)
          .then((data) => {
            dispatch({type: FETCH_SUCCESS});
            if (data && data.status && data.status === 200) {
              console.log(data, 'data serial');
            }
            dispatch({
              type: SETTING_SUCCESS,
              payload: data.data.message,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSaveFormatOCR',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({type: FETCH_ERROR, payload: err.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onSaveSettings',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({type: FETCH_ERROR, payload: err.message});
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSaveSettings', '0', err),
      );
    }
  };
};

//save OCRformat
export const getTrainedData = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      try {
        jwtAxios
          .post('v1/pateRxMgmt/getTrainedData', body)
          .then((data) => {
            dispatch({type: FETCH_SUCCESS});
            if (data && data.status && data.status === 200) {
              console.log(data, 'data serial');
            }
            dispatch({
              type: GET_TRAINED_DATA,
              payload: data?.data?.body,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getTrainedData',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({type: FETCH_ERROR, payload: err.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onSaveSettings',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({type: FETCH_ERROR, payload: err.message});
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSaveSettings', '0', err),
      );
    }
  };
};

export const onShowMasterMergedHospitalsList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/showMergedHospitalsmasterJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/showMergedHospitalsmaster', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: SHOW_MERGED_HOSPITALS_MASTER,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedHospitalsList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedHospitalsList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedHospitalsList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowMergedHospitalsList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onSaveMasterMergedHospitalsList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/saveMergedHospitalsmasterJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/doctorMasters/saveMergedHospitalsmaster', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: SAVE_MERGED_HOSPITALS_MASTER,
                      payload: data.data.body + '|' + body.process,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onSaveMasterMergedHospitalsList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onSaveMasterMergedHospitalsList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedHospitalsList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowMergedHospitalsList',
          '0',
          err,
        ),
      );
    }
  };
};

//get doctor master list using jwt token
export const onGetVerifiedDoctors = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/getVerifiedDoctorsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/getVerifiedDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_DOCTOR_MASTER,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowMergedDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};

//get doctor master list using jwt token
export const onGetDoctorDetails = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/getDoctorDetailsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/getDoctorDetails', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_DOCTOR_DATA,
                      payload: data.data.body,
                    });
                    dispatch({
                      type: GET_DOCTOR_DATA_SUCCESS,
                      payload: 'success',
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getDoctorDetailsJwt',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getDoctorDetailsJwt',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getDoctorDetailsJwt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getDoctorDetailsJwt',
          '0',
          err,
        ),
      );
    }
  };
};

// updateDoctorMasterData
export const updateDoctorMasterData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/updateDoctorMasterDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/doctorMasters/updateDoctorMasterData', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_DOCTOR_SUCCESS_MSG,
                  payload: data?.data?.message,
                });
                // dispatch({
                //   type: SHOW_MESSAGE,
                //   payload: 'Doctor info updated successfully',
                // });
              })
              .catch((error) => {
                dispatch({
                  type: UPDATE_DOCTOR_SUCCESS_MSG,
                  payload: 'failure',
                });
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'updateDoctorMasterData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch({
              type: UPDATE_DOCTOR_SUCCESS_MSG,
              payload: 'failure',
            });
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'updateDoctorMasterData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_DOCTOR_SUCCESS_MSG,
            payload: 'failure',
          });
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'updateDoctorMasterData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: UPDATE_DOCTOR_SUCCESS_MSG,
        payload: 'failure',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'updateDoctorMasterData',
          '0',
          err,
        ),
      );
    }
  };
};

export const GetClientMasterListsData = (input) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/getClientMastersListjwt', input)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              input['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/getClientMastersList', input)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_CLIENT_MASTER_DATA,
                      payload: data.data.body ? data.data.body : [],
                    });
                    dispatch({
                      type: MEDIA_MASTER,
                      payload: data.data.body.mediaMaster
                        ? data.data.body.mediaMaster
                        : [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'GetClientMasterListsData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'GetClientMasterListsData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GetClientMasterListsData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetClientMasterListsData',
          '0',
          err,
        ),
      );
    }
  };
};

export const GetClientHospitalData = (body, rowData, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/getClientMasterDatajwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/getClientMasterData', body)
                .then((data) => {
                  if (data.status === 200 && data?.data?.body) {
                    dispatch({type: FETCH_SUCCESS});
                    history.push('/edit-client', rowData);
                    dispatch({
                      type: GET_CLIENT_HOSPITAL_DATA,
                      payload: data.data.body.hospital,
                    });
                    dispatch({
                      type: GET_CLIENT_DEPARTMENT_DATA,
                      payload: data?.data?.body?.department
                        ? data?.data?.body?.department
                        : [],
                    });
                    dispatch({
                      type: CLIENT_MASTER_BILLING_DETAILS,
                      payload: data.data.body.billing,
                    });
                    dispatch({
                      type: GET_CLIENT_MEDIA_DATA,
                      payload: data.data.body.media,
                    });
                    dispatch({
                      type: GET_CLIENT_DATA,
                      payload: data.data.body.clientData,
                    });
                    dispatch({
                      type: GET_LANGUAGES,
                      payload: data.data.body.languages,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'GetClientHospitalData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'GetClientHospitalData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GetClientHospitalData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetClientHospitalData',
          '0',
          err,
        ),
      );
    }
  };
};

export const onUpdateClientMaster = (input) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/updateClientMastersListjwt', input)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              input['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/updateClientMastersList', input)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    if(input?.traumsStatus == 'Yes'){
                      dispatch({
                        type: TRAUMA_CLIENT_TAG,
                        payload:'Yes'
                      });
                    }
                    else{
                      dispatch({
                        type: TRAUMA_CLIENT_TAG,
                        payload:''
                      });
                    }
                    if (data?.data?.message?.includes('Error')) {
                      dispatch({
                        type: UPDATE_CLIENT_HOSPITAl,
                        payload: data.data.message,
                      });
                    } else {
                      let status = data?.data?.body;
                      dispatch({
                        type: UPDATE_CLIENT_HOSPITAl,
                        payload: status?.response,
                      });
                      dispatch({
                        type: GET_CLIENT_NAME,
                        payload: input.clientName,
                      });
                      dispatch({
                        type: GET_CLIENT_ID,
                        payload: status?.clientId ? status?.clientId : 0,
                      });
                      dispatch({
                        type: GET_CLIENT_PORTAL_INFO,
                        payload: input,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onUpdateClientMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onUpdateClientMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateClientMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateClientMaster',
          '0',
          err,
        ),
      );
    }
  };
};

export const onAddClientMaster = (input) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/addClientDetailsjwt', input)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              input['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/addClientDetails', input)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    if(input?.traumsStatus == 'Yes'){
                      dispatch({
                        type: TRAUMA_CLIENT_TAG,
                        payload:'Yes'
                      });
                    }
                    else{
                      dispatch({
                        type: TRAUMA_CLIENT_TAG,
                        payload:''
                      });
                    }
                    if (data?.data?.message?.includes('Error')) {
                      dispatch({
                        type: UPDATE_CLIENT_HOSPITAl,
                        payload: data.data.message,
                      });
                    } else {
                      let status = data?.data?.body;
                      dispatch({
                        type: UPDATE_CLIENT_HOSPITAl,
                        payload: status?.response,
                      });
                      dispatch({
                        type: GET_CLIENT_ID,
                        payload: status?.clientId ? status?.clientId : 0,
                      });
                      dispatch({
                        type: GET_CLIENT_PORTAL_INFO,
                        payload: input,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onAddClientMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onAddClientMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onAddClientMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onAddClientMaster',
          '0',
          err,
        ),
      );
    }
  };
};

//get doctor master list using jwt token
export const operatorReport = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/reportDtls/operatorReportJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/reportDtls/operatorReport', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: OPERATOR_REPORT,
                      payload: data.data.body,
                    });
                    if (
                      data?.data?.body?.reportList &&
                      data?.data?.body?.reportList?.length == 0
                    ) {
                      dispatch({
                        type: OPREPORT_ERROR,
                        payload: 'No records found!',
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'operatorReport',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'operatorReport',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowMergedDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};

//SaveAndUpdateDepartment
export const SaveAndUpdateDepartment = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Department list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/saveDepartmentMasterjwt', body)
        .then((data) => {
          if (data.status === 200) {
            if (data.data.message) {
              if (data.data.message.includes('Duplicate')) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: ALLERGY_SUCCESS,
                  payload: 'duplicate',
                });
              }
            } else {
              //Call Department  insert data
              try {
                body['jwtToken'] = data.data.body['token'];
                jwtAxios
                  .post('v1/master/saveDepartmentMaster', body)
                  .then((data) => {
                    if (data.status === 200) {
                      if (!data.data.message.includes('Error:')) {
                        dispatch({type: FETCH_SUCCESS});
                        dispatch({
                          type: ALLERGY_SUCCESS,
                          payload: 'success',
                        });
                      } else {
                        dispatch({type: FETCH_SUCCESS});
                        dispatch({
                          type: ALLERGY_SUCCESS,
                          payload: 'duplicate',
                        });
                        dispatch({
                          type: FETCH_ERROR,
                          payload: (
                            <IntlMessages id='message.somethingWentWrong' />
                          ),
                        });
                      }
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: ALLERGY_SUCCESS,
                        payload: 'failure',
                      });
                      dispatch({
                        type: FETCH_ERROR,
                        payload: (
                          <IntlMessages id='message.somethingWentWrong' />
                        ),
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'SaveAndUpdateDepartment',
                        '0',
                        error,
                      ),
                    );
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'SaveAndUpdateDepartment',
                    '0',
                    err,
                  ),
                );
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'SaveAndUpdateDepartment',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'SaveAndUpdateDepartment',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetApprovedDoctors = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/getApprovedDoctorsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorDtls/getApprovedDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_APPROVED_DOCTORS,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowMergedDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onSaveCameraDetails = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/saveCameraDetailsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorDtls/saveCameraDetails', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    let message = data?.data?.body ? data?.data?.body : '';
                    let msg = message;
                    if (message && !message?.message) msg = JSON.parse(message);

                    dispatch({
                      type: UPDATE_DOC_SCEQ,
                      payload: {...msg, type: body.type},
                    });
                    if (msg?.message) {
                      if (!msg?.message.includes('~')) {
                        dispatch(
                          getClientDoctorDtls({
                            clientId: body.clientId,
                            userCode: body.userCode.toString(),
                          }),
                        );
                      }
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSaveCameraDetails',
          '0',
          err,
        ),
      );
    }
  };
};

export const onUpdateHospitalDepartment = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/updateHospitalDepartmentJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/master/updateHospitalDepartment', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    if (body.method == 'Add') {
                      dispatch({
                        type: UPDATE_HOS_DEPT,
                        payload: body.type,
                      });
                    } else {
                      dispatch({
                        type: REMOVE_HOS_DEPT,
                        payload: data?.data?.message + '|' + body.type,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateHospitalDepartment',
          '0',
          err,
        ),
      );
    }
  };
};

export const onSaveClientFloorDet = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/saveClientFloorDetJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/master/saveClientFloorDet', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: UPDATE_FLOOR_DET,
                      payload: data?.data?.body,
                    });
                    if (body?.clientId && body?.userCode) {
                      dispatch(
                        getHospitalFloorDtls({
                          clientId: body?.clientId ? body?.clientId : 0,
                          hospitalId: null,
                          userCode: body?.userCode,
                          limit: 10,
                          offset: 0,
                        }),
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateHospitalDepartment',
          '0',
          err,
        ),
      );
    }
  };
};

export const getHospitalFloorDtls = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/getHospitalFloor', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_HOSPITAL_FLOOR,
              payload: data?.data?.body?.list ? data?.data?.body?.list : [],
            });
            dispatch({
              type: GET_HOSPITAL_FLOOR_LIST,
              payload: data?.data?.body?.listCount
                ? data?.data?.body?.listCount
                : 0,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getHopsitalFloorDtls',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getHopsitalFloorDtls',
          '0',
          err,
        ),
      );
    }
  };
};

export const getFloorDtls = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/getFloorNames', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_FLOOR_NAME,
              payload: data.data.body,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getHopsitalFloorDtls',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getHopsitalFloorDtls',
          '0',
          err,
        ),
      );
    }
  };
};
export const getConsultingTime = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/consultingTimevalidation', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_CONSULTING_TIME,
              payload: data.data.body,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getHopsitalFloorDtls',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getHopsitalFloorDtls',
          '0',
          err,
        ),
      );
    }
  };
};

export const getClientDoctorDtls = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/getclientDoctor', body)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_CLIENT_DOCTOR,
              payload: data?.data?.body?.data ? data?.data?.body?.data : [],
            });
            dispatch({
              type: GET_CLIENT_DOCTOR_COUNT,
              payload: data?.data?.body?.count ? data?.data?.body?.count : 0,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getHopsitalFloorDtls',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getHopsitalFloorDtls',
          '0',
          err,
        ),
      );
    }
  };
};

export const saveCameraDtls = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/insertCameraDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/insertCameraDtls', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: SAVE_CAMERA_INFO,
                      payload: data.data.body,
                    });

                    let offset = sessionStorage.getItem('cameraOffset');
                    dispatch(
                      listCameraDtls({
                        userCode: body.userCode,
                        offset: parseInt(offset),
                        limit: 10,
                      }),
                    );
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'saveCameraDtls',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'saveCameraDtls',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveCameraDtls',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'saveCameraDtls', '0', err),
      );
    }
  };
};

export const saveLanguageDtls = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/saveLanguageDataJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/saveLanguageData', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: LANGUAGES_SUCCESS,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'saveCameraDtls',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'saveCameraDtls',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveCameraDtls',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'saveCameraDtls', '0', err),
      );
    }
  };
};

export const listCameraDtls = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/listCameraDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/listCameraDtls', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: LIST_CAMERA_INFO,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'listCameraDtls',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'listCameraDtls',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'listCameraDtls',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'listCameraDtls', '0', err),
      );
    }
  };
};

//get symptom master list using jwt token
export const GetLanguageDetails = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      jwtAxios
        .post('v1/master/getLanguageDataJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call symptom User list data - Get symptom list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/master/getLanguageData', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    console.log(data?.data?.body, 'data?.data?.body');
                    dispatch({
                      type: GET_LANGUAGE_LABELS,
                      payload: data?.data?.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'GetMastersListData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'GetMastersListData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'GetMastersListData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};
