import {
    TOTAL_TRA_COMPLETED_COUNT,
    TOTAL_TRA_PENDING_COUNT,
    TOTAL_TRA_INPROGRESS_COUNT,
    TOTAL_TRA_REJECT_COUNT,
    TRAUMA_PEND_COUNT,
    TRAUMA_PEND_LIST,
    TRAUMA_OCR_DET,
    TRAUMA_FIELD_LIST,
    TRAUMA_IMG_LIST,
    TRAUMA_SUCCESS,
    TRAUMA_ERX_DET,
    TRAUMA_FIELD_VALUES,
    TRAUMA_PREVIEW,
    TRAUMA_INPROGRESS_COUNT,
  TRAUMA_INPROGRESS_LIST,
  TRAUMA_REJECTED_COUNT,
  TRAUMA_REJECTED_LIST,
  TRAUMA_COMPLETED_COUNT,
  TRAUMA_COMPLETED_LIST,
  TRAUMA_LIST_RESULT,
  TRAUMA_CLIENT_TAG,
  CITY_LIST,
  GEO_CLIENT_LIST,
  GEO_PRES_LIST
  } from '../../shared/constants/ActionTypes';

  const initialSettings = {
    totPendingCount: '',
    totInprogressCount: '',
    totCompleteCount: '',
    totRejectCount: '',
    traumaPendCount:0,
    traumaPendList:[],
    ocrDet:[],
    fieldList:[],
    imgList:[],
    traumaSucc:'',
    traumaErxdet:{},
    traumaFieldValues:'',
    traumaPreview:'',
    traumaInProgressCount: 0,
    traumaInProgressList: [],
    traumaRejectedCount: 0,
    traumaRejectedList: [],
    traumaCompletedCount: 0,
    traumaCompletedList: [],
    traumsListResult:'',
    traumsClientTag:'',
    cityList:[],
    geoClientList:[],
    geoPresList:[]
  };

  const TraumaReducer = (state = initialSettings, action) => {
    switch (action.type) {
      case GEO_PRES_LIST:
        return {
          ...state,
          geoPresList:  action.payload,
        };
      case TRAUMA_CLIENT_TAG:
        return {
          ...state,
          traumsClientTag:  action.payload,
        };
        case GEO_CLIENT_LIST:
          return {
            ...state,
            geoClientList:  action.payload,
          };
        case CITY_LIST:
          return {
            ...state,
            cityList:  action.payload,
          };
      case TRAUMA_LIST_RESULT:
        return {
          ...state,
          traumsListResult:  action.payload,
        };
      case TRAUMA_SUCCESS:
        return {
          ...state,
          traumaSucc:  action.payload,
        };
        case TRAUMA_PREVIEW:
          return {
            ...state,
            traumaPreview:  action.payload,
          };
        case TRAUMA_FIELD_VALUES:
          return {
            ...state,
            traumaFieldValues:  action.payload,
          };
        case TRAUMA_ERX_DET:
          return {
            ...state,
            traumaErxdet:  action.payload,
          };
         case TRAUMA_OCR_DET:
            return {
              ...state,
              ocrDet:  action.payload,
            };
            case TRAUMA_IMG_LIST:
              return {
                ...state,
                imgList:  action.payload,
              };
        case TRAUMA_FIELD_LIST:
            return {
              ...state,
              fieldList:  action.payload,
            };
        case TRAUMA_PEND_COUNT:
            return {
              ...state,
              traumaPendCount:  action.payload,
            };
        case TRAUMA_PEND_LIST:
            return {
                ...state,
                traumaPendList:  action.payload,
            };
        case TOTAL_TRA_COMPLETED_COUNT:
            return {
              ...state,
              totCompleteCount:  action.payload,
            };
            case TRAUMA_INPROGRESS_COUNT:
              return {
                ...state,
                traumaInProgressCount: action.payload,
              };
            case TRAUMA_INPROGRESS_LIST:
              return {
                ...state,
                traumaInProgressList: action.payload,
              };
            case TRAUMA_REJECTED_COUNT:
              return {
                ...state,
                traumaRejectedCount: action.payload,
              };
            case TRAUMA_REJECTED_LIST:
              return {
                ...state,
                traumaRejectedList: action.payload,
              };
            case TRAUMA_COMPLETED_COUNT:
              return {
                ...state,
                traumaCompletedCount: action.payload,
              };
            case TRAUMA_COMPLETED_LIST:
              return {
                ...state,
                traumaCompletedList: action.payload,
              };
        case TOTAL_TRA_PENDING_COUNT:
            return {
                ...state,
                totPendingCount:  action.payload,
            };
        case TOTAL_TRA_INPROGRESS_COUNT:
        return {
            ...state,
            totInprogressCount:  action.payload,
        };
        case TOTAL_TRA_REJECT_COUNT:
            return {
                ...state,
                totRejectCount:  action.payload,
            };
        default:
            return state;
    }}

export default TraumaReducer;
