import React from 'react';
import {
  TOTAL_TRA_COMPLETED_COUNT,
  TOTAL_TRA_PENDING_COUNT,
  TOTAL_TRA_INPROGRESS_COUNT,
  TOTAL_TRA_REJECT_COUNT,
  FETCH_ERROR,
  TRAUMA_LIST_RESULT,
  FETCH_START,
  FETCH_SUCCESS,
  TRAUMA_PEND_COUNT,
    TRAUMA_SUCCESS,
    TRAUMA_FIELD_VALUES,
    CITY_LIST,
    GEO_CLIENT_LIST,
    GEO_PRES_LIST,
  TRAUMA_PEND_LIST,
    TRAUMA_PREVIEW,
  TRAUMA_INPROGRESS_COUNT,
  TRAUMA_INPROGRESS_LIST,
  TRAUMA_REJECTED_COUNT,
  TRAUMA_REJECTED_LIST,
  TRAUMA_COMPLETED_COUNT,
  TRAUMA_COMPLETED_LIST,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
import IntlMessages from '../../@crema/utility/IntlMessages';
var streamname = 'Redux_Actions';
var filename = 'Trauma.js';

export const onGetTraumaDashboardErxCount = ({userCode, userId}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userCode,
      userId,
    };
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/trauma/getDashboardTotCountJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/trauma/getDashboardTotCount', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    if (
                      data?.data?.body &&
                      Object.keys(data?.data?.body).length > 0
                    ) {
                      //Get erx overallAmazonCompletedCount count and store local
                      dispatch({
                        type: TOTAL_TRA_COMPLETED_COUNT,
                        payload: data?.data?.body
                          ? data?.data?.body.completedDataCount
                          : '0',
                      });
                      let overallCompletedCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallTraCompletedCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallTraCompletedCount',
                                ),
                              )
                            : data?.data?.body
                            ? data?.data?.body.completedDataCount
                            : ''
                          : data?.data?.body
                          ? data?.data?.body.completedDataCount
                          : '';
                      localStorage.setItem(
                        'overallTraCompletedCount',
                        overallCompletedCount,
                      );

                      //Get erx overallAmazonPendingCount count and store local
                      let overallPendingCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallTraPendingCount')
                            ? JSON.parse(
                                localStorage.getItem('overallTraPendingCount'),
                              )
                            : data?.data?.body
                            ? data?.data?.body.pendingDataCount
                            : ''
                          : data?.data?.body
                          ? data?.data?.body.pendingDataCount
                          : '';

                      dispatch({
                        type: TOTAL_TRA_PENDING_COUNT,
                        payload: data?.data?.body
                          ? data?.data?.body.pendingDataCount
                          : '',
                      });

                      localStorage.setItem(
                        'overallTraPendingCount',
                        overallPendingCount,
                      );

                      //Get erx TOTAL_AMAZON_REJECT_COUNT count and store local
                      dispatch({
                        type: TOTAL_TRA_REJECT_COUNT,
                        payload: data?.data?.body
                          ? data?.data?.body.rejectedDataCount
                          : '',
                      });

                      let overallRejectCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallTraRejectCount')
                            ? JSON.parse(
                                localStorage.getItem('overallTraRejectCount'),
                              )
                            : data?.data?.body
                            ? data?.data?.body.rejectedDataCount
                            : ''
                          : data?.data?.body
                          ? data?.data?.body.rejectedDataCount
                          : '';
                      localStorage.setItem(
                        'overallTraRejectCount',
                        overallRejectCount,
                      );
                      //Get erx TOTAL_AMAZON_INPROGRESS_COUNT count and store local
                      dispatch({
                        type: TOTAL_TRA_INPROGRESS_COUNT,
                        payload: data?.data?.body
                          ? data?.data?.body.inProgressDataCount
                          : '',
                      });
                      let overallInProgressCount =
                        localStorage.getItem('isFilter') &&
                        localStorage.getItem('isFilter') === 'true'
                          ? localStorage.getItem('overallTraInProgressCount')
                            ? JSON.parse(
                                localStorage.getItem(
                                  'overallTraInProgressCount',
                                ),
                              )
                            : data?.data?.body
                            ? data?.data?.body.inProgressDataCount
                            : ''
                          : data?.data?.body
                          ? data?.data?.body.inProgressDataCount
                          : '';
                      localStorage.setItem(
                        'onGetTraumaDashboardErxCount',
                        overallInProgressCount,
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetTraumaDashboardErxCount',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetTraumaDashboardErxCount',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetTraumaDashboardErxCount',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetTraumaDashboardErxCount',
          '0',
          err,
        ),
      );
    }
  };
};

export const gettraumaPendingList = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };

    try {
      //Call pending list Token  - Get Token
      jwtAxios
        .post('v1/trauma/geteRxPendingListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/trauma/geteRxPendingList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data?.data?.body;

                    dispatch({
                      type: TRAUMA_PEND_COUNT,
                      payload: getdata?.count,
                    });
                    dispatch({
                      type: TRAUMA_PEND_LIST,
                      payload: getdata?.list,
                    });
                    dispatch({
                      type: TRAUMA_LIST_RESULT,
                      payload: true,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetTraumaPendingListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetTraumaPendingListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetTraumaPendingListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetTraumaPendingListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

export const gettraumaInProgressList = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };

    try {
      //Call In Progress list Token  - Get Token
      jwtAxios
        .post('v1/trauma/geteRxInProgressListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call In Progress list data - Get In Progress list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/trauma/geteRxInProgressList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data?.data?.body;

                    dispatch({
                      type: TRAUMA_INPROGRESS_COUNT,
                      payload: getdata?.count,
                    });
                    dispatch({
                      type: TRAUMA_INPROGRESS_LIST,
                      payload: getdata?.list,
                    });
                    dispatch({
                      type: TRAUMA_LIST_RESULT,
                      payload: true,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetTraumaInProgressListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetTraumaInProgressListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetTraumaInProgressListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetTraumaInProgressListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

export const gettraumaRejectedList = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };

    try {
      //Call Rejected list Token  - Get Token
      jwtAxios
        .post('v1/trauma/geteRxRejectedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Rejected list data - Get Rejected list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/trauma/geteRxRejectedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data?.data?.body;

                    dispatch({
                      type: TRAUMA_REJECTED_COUNT,
                      payload: getdata?.count,
                    });
                    dispatch({
                      type: TRAUMA_REJECTED_LIST,
                      payload: getdata?.list,
                      
                    });
                    dispatch({
                      type: TRAUMA_LIST_RESULT,
                      payload: true,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetTraumaRejectedListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetTraumaRejectedListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetTraumaRejectedListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetTraumaRejectedListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

export const gettraumaCompletedList = ({
  userCode,
  userId,
  status,
  offset,
  limit,
  filtercheckInStartDate,
  filtercheckInEndDate,
  filterfullfillmentStartDate,
  filterfullfillmentEndDate,
  filterpatName,
  filterMedia,
  filterService,
  filterVendor,
  session_id,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      userCode,
      userId,
      status,
      offset,
      limit,
      filtercheckInStartDate,
      filtercheckInEndDate,
      filterfullfillmentStartDate,
      filterfullfillmentEndDate,
      filterpatName,
      filterMedia,
      filterService,
      filterVendor,
      session_id,
    };

    try {
      //Call Completed list Token  - Get Token
      jwtAxios
        .post('v1/trauma/geteRxCompletedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Completed list data - Get Completed list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/trauma/geteRxCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data?.data?.body;

                    dispatch({
                      type: TRAUMA_COMPLETED_COUNT,
                      payload: getdata?.count,
                    });
                    dispatch({
                      type: TRAUMA_COMPLETED_LIST,
                      payload: getdata?.list,
                    });
                    dispatch({
                      type: TRAUMA_LIST_RESULT,
                      payload: true,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetTraumaCompletedListData',
                      body.userCode,
                      error,
                    ),
                  );
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetTraumaCompletedListData',
                  body.userCode,
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetTraumaCompletedListData',
              body.userCode,
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetTraumaCompletedListData',
          body.userCode,
          err,
        ),
      );
    }
  };
};

  export const submittraums = (body) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
  
      try {
        //Call pending list Token  - Get Token
        jwtAxios
          .post('v1/trauma/saveTraumaDetJwt', body)
          .then((data) => {
            if (data.status === 200) {
              //Call pending list data - Get pending list data using token
              try {
                const jwtToken = data.data.body['token'];
                const bodytoken = {
                  jwtToken,
                };
                jwtAxios
                  .post('v1/trauma/saveTraumaDet', bodytoken)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: TRAUMA_PREVIEW,
                        payload: data?.data?.body,
                      });
                      
                   
                  
                    
                    } else {
                      dispatch({
                        type: FETCH_ERROR,
                        payload: <IntlMessages id='message.somethingWentWrong' />,
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onGetDashboardPendingListData',
                        body.userCode,
                        error,
                      ),
                    );
                    dispatch({type: FETCH_ERROR, payload: error.message});
                  });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDashboardPendingListData',
                    body.userCode,
                    err,
                  ),
                );
              }
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: <IntlMessages id='message.somethingWentWrong' />,
              });
            }
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDashboardPendingListData',
                body?.userCode,
                error,
              ),
            );
          });
      } catch (err) {
        dispatch(fetchError(err.response.message));
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetDashboardPendingListData',
            body.userCode,
            err,
          ),
        );
      }
    };
  };

  export const getPatientDtls = (body) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
  
      try {
        //Call pending list Token  - Get Token
        jwtAxios
          .post('v1/trauma/getTraumaPresDet', body)
          .then((data) => {
            if (data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              //Call pending list data - Get pending list data using token
              try {
                const getData = data.data.body;
                dispatch({
                  type: TRAUMA_FIELD_VALUES,
                  payload: getData,
                });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDashboardPendingListData',
                    body.userCode,
                    err,
                  ),
                );
              }
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: <IntlMessages id='message.somethingWentWrong' />,
              });
            }
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDashboardPendingListData',
                body?.userCode,
                error,
              ),
            );
          });
      } catch (err) {
        dispatch(fetchError(err.response.message));
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetDashboardPendingListData',
            body.userCode,
            err,
          ),
        );
      }
    };
  };

  
  export const completePrescription = (body) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
  
      try {
        //Call pending list Token  - Get Token
        jwtAxios
          .post('v1/trauma/completeTraumaPres', body)
          .then((data) => {
            if (data.status === 200) {
              //Call pending list data - Get pending list data using token
              try {
                const getData = data.data.body;
                dispatch({type: FETCH_SUCCESS});
                 dispatch({
                        type: TRAUMA_SUCCESS,
                        payload: 'Success',
                      });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetDashboardPendingListData',
                    body.userCode,
                    err,
                  ),
                );
              }
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: <IntlMessages id='message.somethingWentWrong' />,
              });
            }
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetDashboardPendingListData',
                body?.userCode,
                error,
              ),
            );
          });
      } catch (err) {
        dispatch(fetchError(err.response.message));
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetDashboardPendingListData',
            body.userCode,
            err,
          ),
        );
      }
    };
  };

  export const getCityList = (body) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
  
      try {
        //Call pending list Token  - Get Token
        jwtAxios
          .post('v1/trauma/getCityOptions', body)
          .then((data) => {
            if (data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              //Call pending list data - Get pending list data using token
              try {
                const getData = data.data.body;
                dispatch({
                  type: CITY_LIST,
                  payload: getData?.city,
                });
                dispatch({
                  type: GEO_CLIENT_LIST,
                  payload: getData?.clientList,
                });
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getCityList',
                    body.userCode,
                    err,
                  ),
                );
              }
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: <IntlMessages id='message.somethingWentWrong' />,
              });
            }
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getCityList',
                body?.userCode,
                error,
              ),
            );
          });
      } catch (err) {
        dispatch(fetchError(err.response.message));
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'getCityList',
            body.userCode,
            err,
          ),
        );
      }
    };
  };

  export const getGeoPrescription = (body) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
  
      try {
        //Call pending list Token  - Get Token
        jwtAxios
          .post('v1/trauma/getGeoPresList', body)
          .then((data) => {
            if (data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              //Call pending list data - Get pending list data using token
              try {
                const getData = data.data.body;
                dispatch({
                  type: GEO_PRES_LIST,
                  payload: getData?.list,
                });
                
              } catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getCityList',
                    body.userCode,
                    err,
                  ),
                );
              }
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: <IntlMessages id='message.somethingWentWrong' />,
              });
            }
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getCityList',
                body?.userCode,
                error,
              ),
            );
          });
      } catch (err) {
        dispatch(fetchError(err.response.message));
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'getCityList',
            body.userCode,
            err,
          ),
        );
      }
    };
  };