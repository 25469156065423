/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route configuration files
 * Dependicies : To use @material-ui/core
 ************/

import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {dashBoardConfigs} from './dashboard/index';
import {traumaRouteConfig} from './dashboard/traumaRoute'
import {amazonDashBoardConfigs} from './dashboard/amazonIndex';
import {digitizationDashBoardConfigs} from './dashboard/digitizationIndex';
import {eRxDetailsConfigs} from './eRxDetails';
import {traumsDetailsConfigs} from './traumaDetails';
import {amazoneRxDetailsConfigs} from './amazonDetails';
import {digitizationeRxDetailsConfigs} from './digitizationDetails';
import {userManagementConfigs} from './userManagement';
import {masterConfigs} from './masters';
import {covidVitalsConfig} from './covidVitals';
import {authRouteConfig} from './auth';
import {approvalRouteConfig} from './adminApproval';
import {initialUrl} from '../shared/constants/AppConst';
import {abhaRouteConfig} from './abhaUsers';
import {reportpageConfigs} from './reports';
import {amazonreportpageConfigs} from './amazonReports';
import {searchPatientConfig} from './searchPatient';
import {appointmentBookingConfig} from './appointmentBookings';
import {mergedPatientConfig} from './mergedPatient';
import {mergedDoctorConfig} from './mergedDoctors';
import {mergedHospitalsConfig} from './mergedHospitals';
import {viewPatientConfig} from './viewPatient';
import {erxListConfig} from './erxList';
import {ClientDashboardConfig} from './clientDashboard';
import {nerReportConfig} from './nerReport';
import {nerReportentityruler} from './nerReportentityruler';
import {DoctorMasterConfig} from './DoctorMaster';
import {operatorReport} from './operatorReport';
import {digitizationreportpageConfigs} from './digitizationReport';
import {IPDeRxDetailsConfigs} from './IPDeRxDetails';
import {gangaramReport} from './gangaramReport';
import {groGraphicalReportConfig} from './geoGraphicalReport'

const routeConfigs = [
  ...authRouteConfig,
  ...approvalRouteConfig,
  ...dashBoardConfigs,
  ...traumaRouteConfig,
  ...digitizationreportpageConfigs,
  ...digitizationDashBoardConfigs,
  ...amazonDashBoardConfigs,
  ...userManagementConfigs,
  ...eRxDetailsConfigs,
  ...groGraphicalReportConfig,
  ...traumsDetailsConfigs,
  ...masterConfigs,
  ...amazoneRxDetailsConfigs,
  ...digitizationeRxDetailsConfigs,
  ...covidVitalsConfig,
  ...abhaRouteConfig,
  ...reportpageConfigs,
  ...amazonreportpageConfigs,
  ...searchPatientConfig,
  ...appointmentBookingConfig,
  ...mergedPatientConfig,
  // ...mergedDoctorConfig,
  // ...mergedHospitalsConfig,
  ...viewPatientConfig,
  ...erxListConfig,
  ...ClientDashboardConfig,
  ...nerReportConfig,
  ...nerReportentityruler,
  ...DoctorMasterConfig,
  ...operatorReport,
  ...IPDeRxDetailsConfigs,
  ...gangaramReport,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-401' />,
  },
];

export default routes;
